<template>
  <b-row no-gutters class="credentials__wrapper">
    <b-col cols="12">
      <!-- Awarded section in credentials tab -->
      <div v-if="showAwarded">
        <div class="credentials-content">
          <div class="content-title">
            {{ $t("achievements.awarded") }}
          </div>
          <Award
            v-for="c in awardedCredentials"
            :key="c.id"
            :credentials="c"
            :id="c.instance.key"
            :active="isEnable"
          ></Award>
        </div>
      </div>
      <!-- Pending section in credentials tab -->
      <div v-if="showPending">
        <div class="credentials-content">
          <div class="content-title" v-if="getSelectedCourse.is_enrolled">
            {{ $t("achievements.pending") }}
          </div>
          <Award
            v-for="c in pendingCredentials"
            :key="c.id"
            :credentials="c"
            :active="false"
          ></Award>
        </div>
      </div>
    </b-col>
  </b-row>
</template>
<script>
import Award from "@/components/CourseDetail/Award.vue";
import { mapGetters } from "vuex";
export default {
  props: {
    isEnable: {
      type: Boolean,
      required: true
    },
    credentials: {
      type: [Object, Array],
      required: true
    }
  },
  data() {
    return {
      showAwarded: false,
      showPending: false
    };
  },
  components: {
    Award
  },
  computed: {
    ...mapGetters([
      "getCurrentUnitId",
      "isPrerequisiteCompleted",
      "getSelectedCourse"
    ]),
    awardedCredentials() {
      return this.credentials.filter(x => x.instance);
    },
    pendingCredentials() {
      return this.credentials.filter(x => !x.instance);
    }
  },
  mounted() {
    this.showList();
  },
  methods: {
    showList() {
      this.credentials.forEach(x => {
        if (x.instance) {
          this.showAwarded = true;
        } else {
          this.showPending = true;
        }
      });
    }
  }
};
</script>

<style lang="scss">
.credentials__wrapper {
  margin: 0px;
  .credentials-content {
    .content-title {
      margin-bottom: 24px;
      @include subtitle-regular;
      color: $brand-public-primary;
    }
  }
}
</style>
